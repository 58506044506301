<template>
    <card>
        <div>


            <card class="shadow-none" id="cargos-aceptados">
                <template v-slot:headerTitle>
                    <span class="card-title my-2 h4"><b>Inspección Botiquines</b></span>
                </template>
                <DataTableButtons :items="listaInspeccionBotiquin" :headers="headerInspeccionBotiquin"
                    :keys="inspeccionBotiquinKeys" :actions="showDetailsBotiquines"
                    tableId="datatable_InspeccionBotiquines" parseDate="true"/>
            </card>


            <!-- Modal de Ver Detalles -->

            <b-modal size="xl" v-model="showModal" title="Detalles de la Inspección" hide-footer id="custom-modal"
                class="custom-modal">
                <DataTable :items="elementosBotiquin" :headers="headerElementoBotiquin" :keys="ElementoBotiquinKeys"
                    tableId="datatable_InspeccionElementosBotiquin" />
            </b-modal>

        </div>
    </card>
</template>


<script>
import DataTableButtons from "../../../../components/Datatable/DatatableButtons.vue";
import DataTable from "../../../../components/Datatable/DataTable.vue";
import { core } from "../../../../config/pluginInit";

export default {
    components: {
        DataTableButtons,
        DataTable,
    },
    name: "DataTableContainer",
    data() {
        return {
            usuario: {},


            // Botiquines
            listaInspeccionBotiquin: [],
            headerInspeccionBotiquin: ["Piso del Botiquin", "Fecha inspección", "Fecha siguiente inspección", "Persona responsable", "Persona que inspeccionó", "Cargo de la persona que inspeccionó el botiquín", "Sucursal"],
            inspeccionBotiquinKeys: ["pisoBotiquin", "fechaInspeccionBotiquin", "fechaSigInspeccionBotiquin", "nombreResponsableBotiquin", "nombreInspeccionadorBotiquin", "cargoInspeccionadorBotiquin", "nombreSucursalBotiquin"],

            // Modal data
            showModal: false,
            modalContent: "",
            itemId: null,

            elementosBotiquin: [],
            headerElementoBotiquin: ["Elemento", "Cantidad Existente", "Entregado", "Cantidad Entregada", "Fecha de vencimiento", "Observaciones"],
            ElementoBotiquinKeys: ["sstElementoBotiquin", "cantidadExistente", "entregaItem", "cantidadEntregada", "fechaVencimiento", "observacionItem"],
        };
    },
    async mounted() {
        this.$isLoading(true);
        core.index();
        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        const empresaId = this.usuario.empresa.id;

        await this.getDatalistaInspeccionBotiquin(empresaId);

        this.$isLoading(false);
    },
    methods: {

        async getDatalistaInspeccionBotiquin(empresaId) {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstInspeccion/GetDetallesSstInspeccionBotiquin/" + empresaId,
                });
                this.listaInspeccionBotiquin = res.length > 0 ? res : [];
                console.log("Botiquines obtenidos: ", this.listaInspeccionBotiquin);
            } catch (error) {
                this.listaInspeccionBotiquin = [];
                console.log("err", error);
            }
        },

        async showDetailsBotiquines(id) {

            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstInspeccion/GetSstInspeccionBotiquinCantidades/" + id,
                });

                this.elementosBotiquin = res.length > 0 ? res : [];
                console.log("LOS DATOS DE ESTE ELEMENTO DE Botiquin SON:", this.elementosBotiquin);
            } catch (error) {
                this.elementosBotiquin = [];
                console.log("err", error);
            }

            this.showModal = true;
        },

    },
};
</script>