<style scoped>
.btn-evaluacion {
  border: solid 3px #16a275;
}

.btn-evaluacion:hover {
  border-color: #12d1df;
}

.btn-evaluacion.active {
  border-color: #df122e;
}
</style>

<template>
  <b-container  class="custom-container">
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>Inspección de Elementos</b></h4>
          </template>
          <div class="row justify-content-center text-center pt-4 pb-2 ">
            <div class="row col-xl-2 col-md-2 col-sm-6  offset-sm-0 flex-nowrap justify-content-center ">
              <div class="p-2">
                <button class="btn-evaluacion btn btn-circle btn-secondary" :class="paso == 1 ? 'active' : ''"
                  @click="paso = 1">
                  <span class=" h3">1</span>
                </button>
                <p class="pt-3 h4">Emergencia</p>
              </div>
              <div class="p-2">
                <button class="btn-evaluacion btn btn-circle btn-secondary" :class="paso == 2 ? 'active' : ''"
                  @click="paso = 2">
                  <span class=" h3">2</span>
                </button>

                <p class="pt-3 h4">Botiquines</p>
              </div>
              <div class="p-2">
                <button class="btn-evaluacion btn btn-circle btn-secondary" :class="paso == 3 ? 'active' : ''"
                  @click="paso = 3">
                  <span class=" h3">3</span>
                </button>
                <p class="pt-3 h4">Extintores</p>
              </div>
            </div>
          </div>
        </card>
        <!-- INSPECCIONAR ELEMENTOS -->
        <listarInspeccionElementosEmergencia v-if="paso == 1" />
        <!-- INSPECCIONAR BOTIQUINES -->
        <listarInspeccionElementosBotiquin v-if="paso == 2" />
        <!-- INSPECCIONAR EXTINTORES -->
        <listarInspeccionElementosExtintor v-if="paso == 3" />
     
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";

import listarInspeccionElementosBotiquin from "./ListarInspeccionElementos/listarInspeccionElementosBotiquin.vue";
import listarInspeccionElementosEmergencia from "./ListarInspeccionElementos/listarInspeccionElementosEmergencia.vue";
import listarInspeccionElementosExtintor from "./ListarInspeccionElementos/listarInspeccionElementosExtintor.vue";
export default {
  components: {
    listarInspeccionElementosBotiquin,
    listarInspeccionElementosEmergencia,
    listarInspeccionElementosExtintor,




  },
  name: "sgsstInspeccion",
  data() {
    return {
      paso: "1",
    };
  },



  computed: {
    ...mapGetters(["userLoggedIn"]),
  },


};
</script>


<style scoped>
.container-iframe {
  width: 100%;
  min-height: 70vh;
  position: relative;
}
</style>