<template>
    <card>
        <div>
            <card class="shadow-none" id="cargos-aceptados">
                <template v-slot:headerTitle>
                    <span class="card-title my-2 h4"><b>Inspección Extintores</b></span>
                </template>
                <DataTableButtons :items="listaInspeccionExtintor" :headers="headerInspeccionExtintor"
                    :keys="inspeccionExtintorKeys" :actions="showDetailsExtintor"
                    tableId="datatable_InspeccionExtintores" parseDate="true"/>
            </card>

            <!-- Modal de Ver Detalles -->

            <b-modal size="xl" v-model="showModal" title="Detalles de la Inspección" hide-footer
                class="custom-modal modal-dialog">

                <DataTable :items="elementosExtintor" :headers="headerElementoExtintor" :keys="ElementoExtintorKeys"
                    tableId="datatable_InspeccionElementosExtintor" />
            </b-modal>
        </div>
    </card>
</template>
<style>
.custom-modal .modal-dialog {
    max-width: 200% !important;
    /* Ajusta el tamaño del modal personalizado */
}
</style>

<script>
import DataTableButtons from "../../../../components/Datatable/DatatableButtons.vue";
import DataTable from "../../../../components/Datatable/DataTable.vue";
import { core } from "../../../../config/pluginInit";
export default {
    components: {
        DataTableButtons,
        DataTable
    },
    name: "DataTableContainer",
    data() {
        return {
            usuario: {},

            // Extintores
            listaInspeccionExtintor: [],
            headerInspeccionExtintor: ["Sucursal", "Fecha Inspección", "Fecha Próxima Inspección", "Evaluador SGSST", "Cargo Evaluador SGSST", "Evaluador COPASST", "Cargo Evaluador COPASST", "Evaluador COPASST (Opcional)", "Cargo Evaluador COPASST (Opcional)"],
            inspeccionExtintorKeys: ["nombreSucursalExtintor", "fechaInspeccionExtintor", "fechaSigInspeccionExtintor", "nombreInspeccionadorGSST", "cargoInspeccionadorGSST", "nombreInspeccionadorCOPASST", "cargoInspeccionadorCOPASST", "nombreInspeccionadorCOPASSTDos", "cargoInspeccionadorCOPASSTDos"],

            // Modal data
            showModal: false,
            modalContent: "",

            elementosExtintor: [],
            headerElementoExtintor: ["Código", "Ubicacion", "Tipo", "Capacidad", "Pintura", "Estado", "AutoAdhesivo", "Manija de Transporte", "Manija de Disparos", "Presione", "Manometro", "Boquilla", "Manguera", "Aro de Seguridad", "Corneta", "Señalización", "Soporte", "Acceso al Extintor", "Fecha Recarga", "Fecha Próxima Recarga", "Observaciones"],
            ElementoExtintorKeys: [
                "codigoExtintor",
                "ubicacionExtintor",
                "tipoExtintor",
                "capacidadExtintor",
                "pinturaCilindro",
                "estadoCilindro",
                "autoadhesivo",
                "manijaTransporte",
                "manijaDisparo",
                "presion",
                "manometro",
                "boquilla",
                "manguera",
                "aroSeguridad",
                "corneta",
                "senalizacion",
                "soporteColgar",
                "accesoExtintor",
                "fechaRecargaExtintor",
                "fechaProximaRecargaExtintor",
                "observacionExtintor"],
        };
    },
    async mounted() {
        this.$isLoading(true);
        core.index();
        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        const empresaId = this.usuario.empresa.id;

        await this.getDatalistaInspeccionExtintor(empresaId);
        this.$isLoading(false);
    },
    methods: {

        async getDatalistaInspeccionExtintor(empresaId) {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstInspeccion/GetDetallesSstInspeccionExtintor/" + empresaId,
                });
                this.listaInspeccionExtintor = res.length > 0 ? res : [];
                console.log("Extintores obtenidos: ", this.listaInspeccionExtintor);
            } catch (error) {
                this.listaInspeccionExtintor = [];
                console.log("err", error);
            }
        },

        async showDetailsExtintor(id) {
            this.modalContent = `Detalles del registro con ID: ${id}`;

            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstInspeccion/GetSstInspeccionExtintorItems/" + id,
                });

                //QUITAR LA HORA DE LAS FECHAS
                const formatDate = dateString => {
                    if (!dateString) return ''; // Retorna vacío si no hay fecha
                    const date = new Date(dateString);
                    return date.toISOString().split('T')[0]; // Obtiene solo la parte de la fecha
                };

                res = res.map(item => ({
                    ...item,
                    fechaProximaRecargaExtintor: formatDate(item.fechaProximaRecargaExtintor),
                    fechaRecargaExtintor: formatDate(item.fechaRecargaExtintor),
                }));

                this.elementosExtintor = res.length > 0 ? res : [];
                console.log("LOS DATOS DE ESTE ELEMENTO DE Extintor SON:", this.elementosExtintor);
            } catch (error) {
                this.elementosExtintor = [];
                console.log("err", error);
            }

            this.showModal = true;
        },
    },
};
</script>