<template>
  <card>
    <div >
      <card class="shadow-none" id="resultados-evaluado">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Inspección Elementos</b></span>
        </template>
        <DataTableButtons :items="listaInspeccionElemento" :headers="headerInspeccionElemento"
          :keys="inspeccionElementoKeys" :actions="showDetailsEmergencia" tableId="datatable_InspeccionElementos" parseDate="true"/>
      </card>


      <!-- Modal de Ver Detalles -->
      <b-modal size="xl" v-model="showModal" title="Detalles de la Inspección" hide-footer>
        <div class="d-flex flex-column align-items-center">
          <table class="table table-striped no-border-top mb-5 col-10" v-for="(items, grupo) in datosPorGrupo"
            :key="grupo">

            <thead class="h4">
              <tr>
                <th class="col-3">{{ grupo }}</th>
                <th class="col-1">REVISIÓN</th>
                <th class="col-2">OBSERVACIÓN</th>
              </tr>

            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td style="text-align: left;">
                  {{ item.pregunta }}
                </td>
                <td style="text-align: left;">
                  {{ item.respuesta }}
                </td>
                <td style="text-align: left;">
                  {{ item.observacion }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </card>
</template>

<script>
import DataTableButtons from "../../../../components/Datatable/DatatableButtons.vue";
//import DataTable from "../../../../components/Datatable/DataTable.vue";
import { core } from "../../../../config/pluginInit";

export default {
  components: {
    DataTableButtons,
    //DataTable,
  },
  name: "DataTableContainer",
  data() {
    return {
      usuario: {},
      // Elementos
      listaInspeccionElemento: [],
      headerInspeccionElemento: ["Fecha inspección", "Evaluador", "Piso inspeccionado", "Sucursal"],
      inspeccionElementoKeys: ["fechaInspeccionElemento", "nombreCompletoElemento", "pisoInspeccionElemento", "nombreSucursalElemento"],
      // Modal data
      showModal: false,
      modalContent: "",
      itemId: null,

      elementosEmergencia: [],
      preguntasElementosEmergencia: [],

      ListarRespuestasElementosEmergencia: [],
      datosPorGrupo: [],

      headerElementoEmergencia: ["Pregunta", "Revisado", "Observación"],
      ElementoEmergenciaKeys: ["catalogoId", "respuesta", "observacion"],


    };
  },
  async mounted() {
    this.$isLoading(true);
    core.index();
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    const empresaId = this.usuario.empresa.id;

    await this.getDatalistaInspeccionElementos(empresaId);

    await this.catalogoPreguntas();




    this.$isLoading(false);
  },
  methods: {
    async getDatalistaInspeccionElementos(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstInspeccion/GetDetallesSstInspeccionElementos/" + empresaId,
        });
        this.listaInspeccionElemento = res.length > 0 ? res : [];
        console.log("Elementos de emergencia obtenidos: ", this.listaInspeccionElemento);
      } catch (error) {
        this.listaInspeccionElemento = [];
        console.log("err", error);
      }
    },

    async showDetailsEmergencia(id) {
      this.modalContent = `Detalles del registro con ID: ${id}`;

      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstInspeccion/GetSstInspeccionElementosPregunta/" + id,
        });

        res = res.map(item => ({
          ...item,
          respuesta: item.respuesta === 1 ? 'REVISADO' : 'NO REVISADO'
        }));

        this.elementosEmergencia = res.length > 0 ? res : [];
        console.log("LOS DATOS DE ESTE ELEMENTO DE EMERGENCIA SON:", this.elementosEmergencia);
        this.combinarDatos();
      } catch (error) {
        this.elementosEmergencia = [];
        console.log("err", error);
      }

      this.showModal = true;
    },

    async catalogoPreguntas() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstInspeccion/GetSstInspeccionElementosCatalogo/",
        });
        console.log("LOS CATALOGOS DE PREGUNTAS SON:", res);

        this.preguntasElementosEmergencia = res
      }
      catch (error) {
        console.log("err", error);
      }

    },

    combinarDatos() {
      this.ListarRespuestasElementosEmergencia = this.preguntasElementosEmergencia.flatMap(pregunta =>
        this.elementosEmergencia
          .filter(respuesta => respuesta.catalogoId === pregunta.id)
          .map(respuesta => ({
            id: respuesta.id,
            catalogoId: respuesta.catalogoId,
            observacion: respuesta.observacion,
            respuesta: respuesta.respuesta,
            grupo: pregunta.grupo,
            pregunta: pregunta.pregunta
          }))
      );

      this.datosPorGrupo = this.ListarRespuestasElementosEmergencia.reduce((acc, item) => {
        if (!acc[item.grupo]) {
          acc[item.grupo] = [];
        }
        acc[item.grupo].push(item);
        return acc;
      }, {});
    }



  },


};
</script>