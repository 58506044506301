<template>
    <div class="table-responsive px-4 mt-3">
        <table :id="tableId" class="table table-bordered table-responsive-md table-striped text-center">
            <thead>
                <tr>
                    <th v-for="header in headers" :key="header">{{ header }}</th>
                    <th>Opciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td v-for="key in keys" :key="key">{{ formatItemValue(item[key]) }}</td>
                    <td class=" d-flex justify-content-around">
                        <b-button v-if="actions != null" @click="actions(item.id)" variant="info">Ver más</b-button>
                        <b-button v-b-tooltip.hover :title="titleButton1" v-if="button1 != null"
                            @click="actions1(item.id)" variant="success">
                            <li :class="button1" style="color:white !important"></li>
                        </b-button>
                        <b-button v-b-tooltip.hover :title="titleButton2" v-if="actions2 != null"
                            @click="actions2(item.id)" variant="primary">
                            <li :class="button2" style="color:white !important"></li>
                        </b-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import $ from 'jquery';
import 'datatables.net';
import moment from 'moment/moment';
import "moment/locale/es"
moment.locale('es')
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        keys: {
            type: Array,
            required: true,
        },
        tableId: {
            type: String,
            required: true,
        },
        actions: {
            type: Function,
            required: false,
        },
        actions1: {
            type: Function,
            required: false,
        },
        actions2: {
            type: Function,
            required: false,
        },
        button1: {
            type: String,
            required: false,
        },
        button2: {
            type: String,
            required: false,
        },
        titleButton1: {
            required: false,
        },
        titleButton2: {
            required: false,
        },
        parseDate: {
            required: false,
        }

    },
    watch: {
        items: 'initDataTable'
    },
    methods: {
        formatDate(date) {
           
            return date ? moment(date).locale("es").format('D MMM YYYY') : "No hay fecha registrada";
        },
        isDate(value) {
            // Expresion regular para detectar fechas en formato YYYY-MM-DD y la hora
            const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
            return datePattern.test(value);
        },
        formatItemValue(value) {
            // Verifica si parseDate está activado y si el valor coincide con la eexpresion regular
            if (this.parseDate && this.isDate(value)) {
                return this.formatDate(value);
            }
            return value;
        },

        initDataTable() {
            if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
                $(`#${this.tableId}`).DataTable().destroy();
            }

            this.$nextTick(() => {
                $(`#${this.tableId}`).DataTable({
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                    },
                });
            });
        }
    },
    mounted() {
        this.initDataTable();
    }
};
</script>
